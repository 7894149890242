import { Component, Inject } from '@angular/core';
import { Material } from '../publication/material';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-book-extract-popup',
  templateUrl: './book-extract-popup.component.html',
  styleUrls: ['./book-extract-popup.component.scss']
})
export class BookExtractPopupComponent {
    selectedAlign: 'start' | 'center' | 'end';

    constructor(@Inject(MAT_DIALOG_DATA) public material: Material, private _dialog: MatDialog) {
      this.selectedAlign = 'end';
  }

  closeDialog() {
  this._dialog.closeAll();
  }
}
