import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'pw-publication',
    templateUrl: './publication.component.html',
    styleUrls: ['./publication.component.scss']
})
export class PublicationComponent {

}
