import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, Inject } from '@angular/core';
import { ApiRequestService, TranslatableComponent, TranslationService, TranslationStore } from '@prekog/pw-common';
import { Observable } from 'rxjs';
import { GetTranslationUseCase } from './usecases/translation-usecase/read-translation-usecase';
import { GetTranslationRequest } from './usecases/translation-usecase/read-translation-request';
import { LoadingService } from '@prekog/pw-common';
import { NavigationEnd, Router, Event } from '@angular/router';
import { ProductsResponse } from './publication/productsresponse';
import { Material } from './publication/material';
import { LanguageService } from '@prekog/pw-common';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
    selector: 'pw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends TranslatableComponent implements AfterViewChecked, AfterViewInit {
    cookieValue = '';

    facebookImgUrl = '';

    facebookUrl = 'https://www.facebook.com/profile.php?id=100089168181347';

    loading = false;

    logoUrl = '';

    nameOfTheSite = 'Multiverzum Kiadó';

    products?: Material[];

    title = 'Multiverzum Kiadó';

    production = false;

    downloadFileUrl = "";

    aszfUrl = "";

    dataManagamentUrl = "";

    simplePayLogo = '';

    colorOfMobileSidenav = 'lightgreen';

    constructor(
        private _translateService: TranslationService,
        private _http: HttpClient,
        private _readTranslationfileUseCase: GetTranslationUseCase,
        private _loadingService: LoadingService,
        public router: Router,
        private _apiRequestService: ApiRequestService,
        private _languageService: LanguageService,
        @Inject('PRODUCTION') private _production: boolean,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string

    ) {
        super(_translateService, _http);
        this.production = _production;
        this.downloadFileUrl = _downloadFileUrl;
        this.simplePayLogo = this.downloadFileUrl+'/simplepaylogo'
        this.facebookImgUrl = this.downloadFileUrl+'/facebooklog';
        this.logoUrl = this.downloadFileUrl+'/multiverzumlogo'
    }

    ngAfterViewInit(): void {

    }

    ngAfterViewChecked(): void {
        this.loading = this._loadingService.visibility.getValue();
    }

    override readTranslations(http: HttpClient, language: string): Observable<TranslationStore> {
        const request: GetTranslationRequest = { url: './assets/i18n/' + language + '.json' };
        this._readTranslationfileUseCase.execute(request) as unknown as Observable<TranslationStore>;
        return this._http.get<TranslationStore>('./assets/i18n/' + language + '.json');
    }

    override onInit(): void {
    this.aszfUrl = this.downloadFileUrl+"/aszf";
    this.dataManagamentUrl = this.downloadFileUrl+"/adatkezeles";
        // if (this.production) {
        //     this.setUpAnalytics();
        // }
    }

    // setUpAnalytics() {
    //     this.router.events
    //         .pipe(filter((event: Event | NavigationEnd): event is NavigationEnd => event instanceof NavigationEnd))
    //         .subscribe((event: NavigationEnd | any) => {

    //             gtag('config', 'G-4PB631TGJZ', {
    //                 page_path: event.urlAfterRedirects
    //             });
    //         });
    // }
}
