<div class="discounted-products-container">
    <div class="images">
        <div class="image">
            <img [src]="packageObject.imageUrl!" />
            <div class="shopping-cart-container">
                <div class="shopping-cart">
                    <button (click)="addToCartPackage()">KOSÁRBA</button>
                </div>
            </div>
        </div>
        <div class="sinapsis">
            <div class="content">
                <div class="author-title">
                    <h2 class="title">{{ packageObject.packageName! }} CSOMAG</h2>
                </div>
                <div class="shopping-cart-containerr">
                    <div class="shopping-cartt">
                        <s>{{ originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</s>
                        <h3>Akciós ár: {{ packagePrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</h3>
                        <div class="shopping-cart-mobile">
                            <button (click)="addToCartPackage()">KOSÁRBA</button>
                        </div>
                    </div>
                </div>
                <div class="parameter-box">
                    <div class="param-box">
                        <h3>A csomag tartalma:</h3>
                        <div *ngFor="let product of products; let i = index" class="products">
                            <div
                                [ngStyle]="i % 2 === 0 ? { background: '#ededed' } : { background: 'none' }"
                                routerLink="/book/{{ product.routerLink }}"
                                class="package-content"
                            >
                                <div class="img">
                                    <img src="{{ product.url }}" />
                                </div>
                                <div class="author">
                                    <span>{{ product.author }}</span>
                                </div>
                                <div class="title">
                                    <span>{{ product.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
