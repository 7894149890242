<div class="materials-container">
    <div *ngIf="preorderMaterials.length > 0" class="soon-published">
        <div class="soon-pub">
            <h3>{{ 'materials.preorder' | translate | uppercase }}</h3>
        </div>
    </div>
    <div *ngIf="preorderMaterials.length > 0" class="soon-container-home">
        <div class="main">
            <div *ngFor="let publication of preorderMaterials; let i = index" class="material-container-soon">
                <div *ngIf="publication.routerLink !== 'morte_taska'" class="books">
                    <a routerLink="book/{{ publication.routerLink }}"><img [src]="publication.url" /></a>
                </div>
                <div *ngIf="publication.routerLink !== 'morte_taska'" class="book-infos">
                    <p>{{ publication.author }}</p>
                    <p>{{ publication.title }}</p>
                    <p class="available">{{ 'materials.expectedPublicationDate' | translate }} {{ publication.available | date }}</p>
                    <span *ngIf="publication?.oldPrice !== publication?.originalPrice || publication.hasPreorder">
                        Ár a könyvön: {{ publication.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </span>
                    <p *ngIf="publication.hasPreorder" [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                        Előrendelési ár: {{ publication.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </p>
                </div>
                <div class="shopping-cart">
                    <button [ngStyle]="{ cursor: 'pointer' }" (click)="addToCart(publication)" class="preorder">ELŐRENDELÉS</button>
                </div>
                <div class="book-border-container"></div>
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
</div>
