import { Component, Inject, OnInit } from '@angular/core';
import { Material } from '../publication/material';
import { PublicationService } from '../publication/publication.service';
import { Router } from '@angular/router';
import { ApiRequestService, GoogleTagManagerService } from '@prekog/pw-common';
import { GetPreordersResponse } from './getpreordersresponse';

@Component({
  selector: 'pw-preorders',
  templateUrl: './preorders.component.html',
  styleUrls: ['./preorders.component.scss']
})
export class PreordersComponent implements OnInit{

    getPreordersUrl = '/store/get-preorders';

    preorderMaterials:Material[] = [];

    storeBaseUrl = '';

    constructor(
        private _publicationService: PublicationService,
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _googleTagManagerService: GoogleTagManagerService,
    ) {
        this.storeBaseUrl = _storeBaseUrl;
    }

    ngOnInit(): void {
        this._apiRequestService.sendRequest<{},GetPreordersResponse>(this.storeBaseUrl, true, {}, true, this.getPreordersUrl).subscribe((response)=> {
            this.preorderMaterials = response.productList as Material[];
            this.preorderMaterials.forEach((preorderMaterial)=> {
                preorderMaterial.oldPrice = preorderMaterial.originalPrice;
                preorderMaterial.originalPrice = preorderMaterial.preorderPrice;
            })
            this._googleTagManagerService.viewProductItemList(this.preorderMaterials);
        })
    }

    addToCart(material: Material) {
        if (!sessionStorage.getItem('token') && material.hasPreorder) {
            sessionStorage.setItem('material', JSON.stringify(material));
            this._publicationService.openLogin(material);
        } else {
            this._publicationService.addToCart(material);
        }
    }

}
