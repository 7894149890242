import { Component, Inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ApiRequestService, ShoppingcartService } from '@prekog/pw-common';
import { Material } from '../publication/material';
import { Package } from '../specialoffer/package';
import { Router } from '@angular/router';
import { PackageProductResponse } from './packageproductresponse';
import { SpecialofferService } from '../specialoffer/specialoffer.service';
import { PackageProductRequest } from './packageproductrequest';
import { GoogleTagManagerService } from '@prekog/pw-common';
import { GetPackageProductUseCase } from './usecase/get-package-product-usecase';
import { Observable } from 'rxjs';

@Component({
    selector: 'pw-discountedproduct',
    templateUrl: './discountedproduct.component.html',
    styleUrls: ['./discountedproduct.component.scss']
})
export class DiscountedproductComponent implements OnInit {
    routerLink = '';
    orderBackendUrl = '';
    packageObject: Package = {};
    products: Material[] = [];
    packageProductsUrl = '/store/get-packageproduct';
    frontendUrl = window.location.origin;
    id = 0;
    packageProductRequest: PackageProductRequest = {};
    packagePrice = 0;
    originalPrice = 0;
    storeBackendUrl = '';

    constructor(
        private _specialOfferService: SpecialofferService,
        private _router: Router,
        private _metaService: Meta,
        private _apiRequestService: ApiRequestService,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        private _shoppingcartService: ShoppingcartService,
        private _googleTagManagerService: GoogleTagManagerService,
        private _getPackageProductUseCase: GetPackageProductUseCase
    ) {
        this.orderBackendUrl = _orderBackendUrl;
        this.storeBackendUrl = _storeBackendUrl;
    }

    ngOnInit(): void {
        //TODO backend works with id request, has to be solved. Backend needs id to get products. Put id to session or make a service to store it
        let last = window.location.href.split('/').pop();
        const lastSegmentOfUrlList = last?.split('?');

        if (lastSegmentOfUrlList) {
            const lastSegmentOfUrl = lastSegmentOfUrlList[0] as string;
            this.routerLink = lastSegmentOfUrl as string;
        }
        this.packageProductRequest = {
            routerLink: this.routerLink
        };
        this._metaService.updateTag({ property: 'og:url', content: this.frontendUrl + this._router.url });
        (this._getPackageProductUseCase.execute(this.packageProductRequest) as Observable<PackageProductResponse>).subscribe(
            (packageProductResponse) => {
                const response = packageProductResponse as PackageProductResponse;
                this.packageObject = response.packageObject as Package;
                this.products = response.products as Material[];
                this.products.forEach((product) => {
                    this.packagePrice += product.packagePrice!;
                    this.originalPrice += product.originalPrice!;
                });

                //adding select_item into dataLayer to google
                this._googleTagManagerService.selectPackageItem(this.packageObject, this.products);

                this.initializeProducts();
            }
        );
    }

    initializeProducts() {
        // this.filteredPublication = this.publication;

        this.products.forEach((value, index) => {
            if (value.webshopPriceActive) {
                value.oldPrice = value.originalPrice;
                value.originalPrice = value.webshopPrice;
            }
            //TODO it is because of facebook link that I have used includes not ==, fb puts a token after url
        });
        if (this.routerLink.includes(this.packageObject.routerLink as string)) {
            this.id = this.packageObject.id!;
            const imageUrl = this.packageObject.imageUrl?.substring(1);

            this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
        }
    }

    addToCartPackage() {
        this.initializePackage();
        this._shoppingcartService.addToCartPackage(this.products, this.packageObject);
    }

    initializePackage() {
        this.products.forEach((value) => {
            value.oldPrice = value.originalPrice;
            value.originalPrice = value.packagePrice;
            value.packageName = this.packageObject.packageName;
        });
        this.products[0].firstElementOfPackage = true;
    }
}
