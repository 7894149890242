<div *ngIf="router.url !== '/'" class="special-offer-container">
    <div *ngIf="discountedMaterials.length > 0" class="published">
        <div class="pub-container">
            <div class="pubb">
                <h3>{{ homeConfig.name | uppercase }}</h3>
            </div>
            <div class="easter-discount-text">
                <h3>
                    Válogass akciós könyveink közül és vásárolj akár
                    <span [ngStyle]="{ color: 'red', 'font-size': '20px' }">40%</span> kedvezménnyel!
                </h3>
            </div>
        </div>
    </div>
    <div *ngIf="discountedMaterials.length > 0" class="materials-container-special-offer">
        <div class="material-container-published">
            <div class="books-container">
                <div *ngFor="let publication of discountedMaterials" class="book">
                    <div class="books">
                        <a routerLink="/book/{{ publication.routerLink }}"><img [src]="publication.url" /></a>
                    </div>
                    <div class="book-infos">
                        <p>{{ publication.author }}</p>
                        <p>{{ publication.title }}</p>
                        <span *ngIf="publication.webshopPriceActive || publication.discountPriceActive">
                            {{ 'price.originalPrice' | translate }} {{ publication.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </span>
                        <p [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                            {{ 'price.discountedPrice' | translate }} {{ publication.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </p>
                    </div>
                    <div class="shopping-cart">
                        <button (click)="addToCartDiscountedBook(publication)">{{ 'shoppingCart.putIntoCart' | translate }}</button>
                    </div>
                </div>
                <div class="placeholder"></div>
                <div class="placeholder"></div>
                <div class="placeholder"></div>
                <div class="placeholder"></div>
                <div class="placeholder"></div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="router.url === '/' && discountedMaterials.length > 0" class="special-offer-container-home">
    <div class="published">
        <div class="pub-container">
            <div class="pubb">
                <h3>{{ 'specialOffer.discountedBooks' | translate }}</h3>
            </div>

            <div class="easter-discount-text">
                <h3>
                    Válogass akciós könyveink közül és vásárolj akár
                    <span [ngStyle]="{ color: 'red', 'font-size': '20px' }">40%</span> kedvezménnyel!
                </h3>
            </div>
        </div>
    </div>

    <div class="materials-container-special-offer">
        <div class="material-container-published">
            <div class="books-container">
                <div *ngFor="let publication of discountedMaterials" class="book">
                    <div class="books">
                        <a routerLink="/book/{{ publication.routerLink }}"><img [src]="publication.url" /></a>
                    </div>
                    <div class="book-infos">
                        <p>{{ publication.author }}</p>
                        <p>{{ publication.title }}</p>
                        <span *ngIf="publication.webshopPriceActive || publication.discountPriceActive">
                            {{ 'price.originalPrice' | translate }} {{ publication.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </span>
                        <p [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                            {{ 'price.discountedPrice' | translate }} {{ publication.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </p>
                    </div>
                    <div class="shopping-cart">
                        <button (click)="addToCartDiscountedBook(publication)">{{ 'shoppingCart.putIntoCart' | translate }}</button>
                    </div>
                </div>
                <div class="placeholder"></div>
                <div class="placeholder"></div>
                <div class="placeholder"></div>
                <div class="placeholder"></div>
                <div class="placeholder"></div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="router.url === '/'" class="special-offer-container-home"></div>
