import { ApiRequestService, LogService, UseCase, Void } from '@prekog/pw-common';
import { DiscountedProductResponse } from '../discountedproductresponse';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GetDiscountedProductsUseCase extends UseCase<Void, Observable<DiscountedProductResponse>> {

    getdiscountedProductsUrl = '/store/get-discountedproducts';

    storeBackendUrl = '';

    constructor(
        private _apiRequestService: ApiRequestService,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        _logService: LogService,
        protected override _http: HttpClient
    ) {
        super(_logService, _http);
        this.storeBackendUrl = _storeBackendUrl;
    }

    protected override executeBusinessLogic(request: void | Void): void | Observable<DiscountedProductResponse> {
           return this._apiRequestService
            .sendRequest(this.storeBackendUrl, true, {}, true, this.getdiscountedProductsUrl);
    }
}
