import { Injectable } from "@angular/core";
import { BusinessLogicError, LogService, UseCase, Void } from "@prekog/pw-common";
import { BannerThumbs } from "src/app/slideshow/banner-thumbs";
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class GetBlogThumbsUseCase extends UseCase<Void, Observable<BannerThumbs[]>>  {

    bannersBlogUrl = '../../assets/configurations/blog-thumb.json';

    constructor(_logService: LogService, protected override _http: HttpClient) {
        super(_logService, _http);
    }

    protected override validate(request:void | Void) {
        //TODO validate if bannersBlogUrl exists or not!!!
    }

    protected override executeBusinessLogic(request: void | Void): void | Observable<BannerThumbs[]> {
        return this._http.get<BannerThumbs[]>(this.bannersBlogUrl).pipe(
            map((bannerThumbs:BannerThumbs[]) => {
                if (!bannerThumbs) {
                    throw new BusinessLogicError("Couldn't read bannerThumbs file content!");
                }
                return bannerThumbs

            }));
    }




}
