import { Component, Inject } from '@angular/core';
import { Material } from '../publication/material';
import { DiscountedProductResponse } from '../specialoffer/discountedproductresponse';
import { ApiRequestService, GoogleTagManagerService, ShoppingcartService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { SpecialofferService } from '../specialoffer/specialoffer.service';
import { GetDiscountedProductsUseCase } from '../specialoffer/usecase/get-discounted-products-usecase';
import { Observable } from 'rxjs';
import { FindHomeConfigRequest } from '../published/findhomeconfigrequest';
import { FindHomeConfigResponse } from '../published/findhomeconfigresponse';
import { HomeConfig } from '../home/home-config';

@Component({
  selector: 'pw-discounted-products',
  templateUrl: './discounted-products.component.html',
  styleUrls: ['./discounted-products.component.scss']
})
export class DiscountedProductsComponent {

    getdiscountedProductsUrl = '/store/get-discountedproducts';
    discountedResponse: DiscountedProductResponse = {};
    discountedMaterials: Material[] = [];
    products: Material[] = [];
    productsForPackagePrices: Material[] = [];
    storeBackendUrl = '';
    homeConfig:HomeConfig = {};
    configuratorBaseUrl = '';
    getHomeConfigByComponentUrl = '/websiteconfigurator/find-homeconfig';

    constructor(
        private _googleTagManagerService: GoogleTagManagerService,
        public router: Router,
        private _shoppingcartService: ShoppingcartService,
        private _specialOfferService: SpecialofferService,
        private _apiRequestService: ApiRequestService,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        private _getDiscountedProductUseCase: GetDiscountedProductsUseCase,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string

    ) {
        this.storeBackendUrl = _storeBackendUrl;
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        (this._getDiscountedProductUseCase.execute({}) as Observable<DiscountedProductResponse>)
            .subscribe((discountedProductsResponse) => {
                this.discountedResponse = discountedProductsResponse as DiscountedProductResponse;

                this.discountedMaterials = this.discountedResponse.productList as Material[];
                this.discountedMaterials.forEach((product) => {
                    product.oldPrice = product.originalPrice;
                    product.originalPrice = product.discountPrice;
                });

                this.getHomeConfigName();

                this._googleTagManagerService.viewProductItemList(this.discountedMaterials);


            });
    }

    getHomeConfigName() {
        const findHomeConfigRequest: FindHomeConfigRequest = {
            component: 'pw-discounted-products'
        };
        this._apiRequestService
            .sendRequest<FindHomeConfigRequest, FindHomeConfigResponse>(
                this.configuratorBaseUrl,
                true,
                findHomeConfigRequest,
                true,
                this.getHomeConfigByComponentUrl
            )
            .subscribe((response) => {
                this.homeConfig = response.homeConfig as HomeConfig;
            });
    }
    addToCartDiscountedBook(material: Material) {
        this.initializeDiscountedProduct(material);
        this._shoppingcartService.addToCart(material);
    }

    initializeDiscountedProduct(material: Material) {
        material.oldPrice = material.originalPrice;
        material.originalPrice = material.discountPrice;
    }


}
