import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

export function getOrderBackendUrl(): string {
    return environment.backendOrderUrl;
}
export function getEmailBackendUrl(): string {
    return environment.backendEmailUrl;
}
export function getStoreBackendUrl(): string {
    return environment.backendStoreUrl;
}

export function getApiUrl(): string {
    return environment.apiUrl;
}
export function getBackendAuthUrl(): string {
    return environment.backendAuthUrl;
}
export function getProduction(): boolean {
    return environment.production;
}

export function getDownloadFileUrl(): string {
    return environment.downloadUrl;
}

export function getConfiguratorUrl(): string {
    return environment.backendConfiguratorUrl;
}


const providers = [
    { provide: 'ORDER_BACKEND_URL', useFactory: getOrderBackendUrl, deps: [] },
    { provide: 'EMAIL_BACKEND_URL', useFactory: getEmailBackendUrl, deps: [] },
    { provide: 'STORE_BACKEND_URL', useFactory: getStoreBackendUrl, deps: [] },
    { provide: 'API_URL', useFactory: getApiUrl, deps: [] },
    { provide: 'AUTH_BACKEND_URL', useFactory: getBackendAuthUrl, deps: [] },
    { provide: 'DOWNLOAD_FILE_URL', useFactory: getDownloadFileUrl, deps: [] },
    { provide: 'CONFIGURATOR_BASE_URL', useFactory: getConfiguratorUrl, deps: [] },
    { provide: 'PRODUCTION', useFactory: getProduction(), deps: [] }
];

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

const googleContainerIdWithQuationMark = '\''+environment.googleTagContainerId+'\'';
const googleTagIdWithQuationMark = '\''+environment.googleTagId+'\'';

const scriptSrc = document.createElement('script');
scriptSrc.async = true;
scriptSrc.src = "https://www.googletagmanager.com/gtag/js?id="+environment.googleTagId;
document.head.appendChild(scriptSrc);

const script = document.createElement('script');
script.innerHTML = `  window.dataLayer = window.dataLayer || [];
function gtag() {
    dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', ${googleTagIdWithQuationMark}, { 'debug_mode':true });`;
document.head.appendChild(script);

const script2 = document.createElement('script');
script2.innerHTML = `  (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', ${googleContainerIdWithQuationMark});`;
document.head.appendChild(script2);

const noscript = document.createElement('noscript');
const iframe = document.createElement('iframe');
iframe.setAttribute('src',"https://www.googletagmanager.com/ns.html?id="+environment.googleTagContainerId);
iframe.setAttribute('height',"0");
iframe.setAttribute('width',"0");
iframe.setAttribute('style',"display: none; visibility: hidden");
noscript.appendChild(iframe);
document.head.appendChild(noscript);

//Google reCaptcha link
const reCaptchaScript = document.createElement('script');
reCaptchaScript.src = "https://www.google.com/recaptcha/api.js?render="+environment.reCaptchaSiteKey;
document.head.appendChild(reCaptchaScript);

const reCaptchaExecuteFunction = document.createElement('script');
reCaptchaExecuteFunction.innerHTML = ` function onClick(e) {
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute(${environment.reCaptchaSiteKey}, {action:'submit'}).then(function(token) {
          // Add your logic to submit to your backend server here.
      });
    });
  }`;

document.head.appendChild(reCaptchaExecuteFunction);





