import { ApiRequestService, BusinessLogicError, LogService, UseCase, ValidationError } from '@prekog/pw-common';
import { PackageProductRequest } from '../packageproductrequest';
import { PackageProductResponse } from '../packageproductresponse';
import { Inject, Injectable } from '@angular/core';
import { Observable,map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GetPackageProductUseCase extends UseCase<PackageProductRequest, Observable<PackageProductResponse>> {
    packageProductsUrl = '/store/get-packageproduct';

    storeBackendUrl = '';

    constructor(
        private _apiRequestService: ApiRequestService,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        _logService: LogService,
        protected override _http: HttpClient
    ) {
        super(_logService, _http);
        this.storeBackendUrl = _storeBackendUrl;
    }

    protected override validate(request: PackageProductRequest) {
        if (request.routerLink === null || '') {
            throw new ValidationError('The routerLink for Package is null');
        }
    }
    protected override executeBusinessLogic(request: void | PackageProductRequest): void | Observable<PackageProductResponse> {
        return this._apiRequestService.sendRequest(this.storeBackendUrl, true, request, true, this.packageProductsUrl);

    }
}
