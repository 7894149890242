<div *ngIf="pressArticleList!.length > 0" class="press-article-main-container">
    <div class="title-container">
        <div class="title">
            <h3>{{ 'pressArticle.title' | translate | uppercase }}</h3>
        </div>
    </div>
    <div class="press-article-container">
        <div class="press-art">
            <div class="press-article-img-container">
                <div class="press-article-img">
                    <div class="url">
                        <!-- <img src="{{ pressArticleUrl }}" /> -->
                    </div>
                </div>
            </div>

            <div class="press-article-list">
                <div [ngStyle]="{ 'margin-bottom': '16px' }" *ngFor="let pressArticle of pressArticleList" class="press">
                    <span>{{ pressArticle.quotation }} – </span>
                    <span [ngStyle]="{ 'font-style': 'italic' }">{{ pressArticle.author }}</span
                    ><br />
                    <a href="{{ pressArticle.link }}" target="_blank"
                        ><span>{{ pressArticle.linkName }}</span></a
                    >
                </div>
            </div>
        </div>
    </div>
</div>
