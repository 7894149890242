import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { interval } from 'rxjs';
import { Banner } from './banner';
import { HttpClient } from '@angular/common/http';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { GetSlideshowUseCase } from './usecase/get-slideshow-usecase';
import { Observable } from 'rxjs/internal/Observable';
import { GetBannersResponse } from './usecase/getbannersresponse';
import { ApiRequestService } from '@prekog/pw-common';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-slideshow',
    templateUrl: './slideshow.component.html',
    styleUrls: ['./slideshow.component.scss'],
    animations: [trigger('fade', [state('void', style({ opacity: 0.3 })), transition('void => *', [animate('1.5s ease-out')])])]
})
export class SlideshowComponent implements OnInit {
    alive = false;

    hasNotAnimation = false;

    imagesLoaded = 0;

    imagesUrl: Banner[] = [];
    imagesUrlHelper: Banner[] = [];

    index = 0;

    loading = true;

    numImages = 0;

    banners: Banner[] = [];

    //bannerUrl = './assets/configurations/banners.json';

    bannerUrl = '/websiteconfigurator/get-banners';

    numberOfClickableSlideshow: Array<number> = [];

    routerLink = '';

    downloadFileUrl = '';

    logourl = '';

    banner: Banner = {};

    configuratorBaseUrl = '';

    constructor(
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _getSlideshowUseCase: GetSlideshowUseCase,
        private _router: Router,
        private _http: HttpClient,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        private _apiRequestService: ApiRequestService
    ) {
        this.downloadFileUrl = _downloadFileUrl;
        this.configuratorBaseUrl = this._configuratorBaseUrl;
    }

    ngOnInit() {
        //we need imagesHelper to store reverse of books,because multiverzum banner is always the first cannot be reversed
        //this._http.get<Banner[]>(this.bannerUrl).subscribe({
        this._apiRequestService
            .sendRequest<{}, GetBannersResponse>(this.configuratorBaseUrl, true, {}, true, this.bannerUrl)
            .subscribe((response) => {
                this.imagesUrl.push(response.bannerConfigEntityList![0]);
                this.banner = response.bannerConfigEntityList![0];
                this.logourl = response.bannerConfigEntityList![0].imageUrl!;
                response.bannerConfigEntityList = response.bannerConfigEntityList!.sort((a, b) => a.orderNumber! - b.orderNumber!);
                response.bannerConfigEntityList!.forEach((banner, index) => {
                    const routerLinkArray = banner.link?.split('/');
                    if (routerLinkArray?.length! > 2) {
                        const routerLink = '/' + routerLinkArray![3] + '/' + routerLinkArray![4];
                        console.log(routerLink);
                        banner.routerLink = routerLink;
                    }
                    else {
                        banner.routerLink = "/";
                    }
                    if (index > 0) {
                        this.imagesUrlHelper.push(banner);
                    }
                });
                //this.imagesUrlHelper.reverse();
                this.imagesUrlHelper.forEach((url) => {
                    this.imagesUrl.push(url);
                });

                this.numImages = this.imagesUrl.length;

                this.imagesUrl.forEach((x) => {
                    const image = new Image();
                    image.onload = () => {
                        this.imagesLoaded++;
                        this.loading = this.imagesLoaded != this.numImages;
                    };

                    image.src = x.imageUrl!;
                });
                const subscription = interval(8000).subscribe(() => {
                    if (this.alive == true) {
                        this.index = this.index - 1;
                        this.banner = this.imagesUrl[this.index];
                        setTimeout(() => subscription.unsubscribe(), 3000);
                    }
                    if (!this.loading) {
                        this.index = (this.index + 1) % this.numImages;
                        this.banner = this.imagesUrl[this.index];
                    }
                });
                for (let i = this.imagesUrl.length - 1; i > this.imagesUrl.length - (this.imagesUrl.length + 1); i--) {
                    this.numberOfClickableSlideshow.push(i);
                }
                this.numberOfClickableSlideshow.reverse();
            });
    }

    showNextImage() {
        this.alive = true;
        if (this.index < this.imagesUrl.length - 1) {
            this.index += 1;
            this.banner = this.imagesUrl[this.index];
        } else if (this.index == this.imagesUrl.length - 1) {
            this.index = 0;
            this.banner = this.imagesUrl[this.index];
        }
    }

    showPreviousImage() {
        this.alive = true;
        if (this.index >= 1) {
            this.index = this.index - 1;
            this.banner = this.imagesUrl[this.index];
        } else if (this.index == 0) {
            this.index = this.imagesUrl.length - 1;
            this.banner = this.imagesUrl[this.index];
        }
    }

    clickToChangeImage(number: number) {
        this.alive = true;
        this.index = number;
        this.banner = this.imagesUrl[this.index];
    }

    isRouteActive(url: string): boolean {
        const matchOptions: IsActiveMatchOptions = {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored'
        };
        return this._router.isActive(url, matchOptions);
    }
}
