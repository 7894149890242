import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntroductionComponent, OrderConfirmationComponent, VerificationComponent,VerificationMessageComponent,VerificationForgotPasswordComponent, VerificationForgotpasswordMessageComponent, PersonalDataComponent,MyordersComponent, SigninComponent, SignupComponent} from '@prekog/pw-common';
import { ContactComponent } from '@prekog/pw-common';
import { PublicationComponent } from './publication/publication.component';
import { BooksComponent } from './books/books.component';
import { BlogComponent } from './blog/blog.component';
import { HomeComponent } from './home/home.component';
import { BlogsComponent } from './blogs/blogs.component';
import { CartsumComponent } from '@prekog/pw-common';
import { CartsummaryComponent } from '@prekog/pw-common';
import { TypeofdeliveryComponent } from '@prekog/pw-common';
import { TypeofpaymentComponent } from '@prekog/pw-common';
import { PaymentstatusComponent } from '@prekog/pw-common';
import { CommonModule } from '@angular/common';
import { SpecialofferComponent } from './specialoffer/specialoffer.component';
import { DiscountedproductComponent } from './discountedproduct/discountedproduct.component';
import { ForgotpasswordComponent } from '@prekog/pw-common';






const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'book', component: PublicationComponent },
    { path: 'book/:routerLink', component: BooksComponent },
    { path: 'blog', component: BlogsComponent },
    { path: 'introduction', component: IntroductionComponent },
    { path: 'blog/:id', component: BlogComponent },
    { path: 'cartsum', component: CartsumComponent },
    { path: 'cartsummary', component: CartsummaryComponent },
    { path: 'delivery', component: TypeofdeliveryComponent },
    { path: 'payment', component: TypeofpaymentComponent },
    { path: 'orderconfirmation', component: OrderConfirmationComponent },
    { path: 'paymentstatus', component: PaymentstatusComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'signin', component: SigninComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'forgotpassword', component: ForgotpasswordComponent },
    { path: 'verification/:id', component: VerificationComponent},
    { path: 'verification-forgotpassword/:id', component: VerificationForgotPasswordComponent},
    { path: 'verificationmessage', component: VerificationMessageComponent},
    { path: 'verificationforgotpasswordmessage', component: VerificationForgotpasswordMessageComponent},
    { path: 'specialoffer', component:SpecialofferComponent},
    { path: 'specialoffer/:routerLink', component:DiscountedproductComponent},
    { path: 'personaldata', component:PersonalDataComponent},
    { path: 'myorders', component:MyordersComponent}








];

@NgModule({
    imports: [RouterModule.forRoot(routes),  CommonModule],
    exports: [RouterModule]
})
export class AppRoutingModule {}
