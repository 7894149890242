import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, GoogleTagManagerService, ShoppingcartService } from '@prekog/pw-common';
import { SpecialofferService } from '../specialoffer/specialoffer.service';
import { Material } from '../publication/material';
import { PackageProductRequest } from '../discountedproduct/packageproductrequest';
import { Package } from '../specialoffer/package';
import { GetPackageProductUseCase } from '../discountedproduct/usecase/get-package-product-usecase';
import { PackageProductResponse } from '../discountedproduct/packageproductresponse';
import { Observable } from 'rxjs';
import { GetPackagesResponse } from './getpackagesresponse';
import { HomeConfig } from '../home/home-config';
import { FindHomeConfigRequest } from '../published/findhomeconfigrequest';
import { FindHomeConfigResponse } from '../published/findhomeconfigresponse';

@Component({
    selector: 'pw-packages',
    templateUrl: './packages.component.html',
    styleUrls: ['./packages.component.scss']
})
export class PackagesComponent {
    getPackagesUrl = '/store/get-packages';
    packages: Package[] = [];
    packageProductsUrl = '/store/get-packageproduct';
    packageObject: Package = {};
    products: Material[] = [];
    packageProductRequest: PackageProductRequest = {};
    productsForPackagePrices: Material[] = [];
    storeBackendUrl = '';
    getHomeConfigByComponentUrl = '/websiteconfigurator/find-homeconfig';
    configuratorBaseUrl = '';
    homeConfig: HomeConfig = {};

    constructor(
        private _googleTagManagerService: GoogleTagManagerService,
        public router: Router,
        private _shoppingcartService: ShoppingcartService,
        private _specialOfferService: SpecialofferService,
        private _apiRequestService: ApiRequestService,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        private _getPackageProductUseCase: GetPackageProductUseCase,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string
    ) {
        this.storeBackendUrl = _storeBackendUrl;
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        this._apiRequestService
            .sendRequest<{}, GetPackagesResponse>(this.storeBackendUrl, true, {}, true, this.getPackagesUrl)
            .subscribe((response) => {
                this.packages = response.packageList as Package[];
                console.log(this.packages);
                this.packages.forEach((packagee) => {
                    this.getPackageProducts(response.packageList as Package[], packagee);
                    this.getHomeConfigName();
                });
            });
    }
    getHomeConfigName() {
        const findHomeConfigRequest: FindHomeConfigRequest = {
            component: 'pw-packages'
        };
        this._apiRequestService
            .sendRequest<FindHomeConfigRequest, FindHomeConfigResponse>(
                this.configuratorBaseUrl,
                true,
                findHomeConfigRequest,
                true,
                this.getHomeConfigByComponentUrl
            )
            .subscribe((response) => {
                this.homeConfig = response.homeConfig as HomeConfig;
            });
    }
    getPackageProducts(packages: Package[], packagee: Package) {
        this.packages = packages;

        this.packageProductRequest = {
            routerLink: packagee.routerLink
        };

        (this._getPackageProductUseCase.execute(this.packageProductRequest) as Observable<PackageProductResponse>).subscribe(
            (packageProductResponse) => {
                const response = packageProductResponse as PackageProductResponse;
                this.packageObject = response.packageObject as Package;
                this.productsForPackagePrices = response.products as Material[];
                let packagePrice = 0;
                let originalPrice = 0;
                this.productsForPackagePrices.forEach((product) => {
                    packagePrice += product.packagePrice!;
                    originalPrice += product.originalPrice!;
                });
                packagee.packagePrice = packagePrice;
                packagee.originalPrice = originalPrice;

                this._googleTagManagerService.viewPackageItemList(this.packages);
            }
        );
    }
    getId(packag: Package) {
        this._specialOfferService.id = packag.id!;
    }

    addToCartPackage(routerLink: string) {
        this.packageProductRequest = {
            routerLink: routerLink
        };

        (this._getPackageProductUseCase.execute(this.packageProductRequest) as Observable<PackageProductResponse>).subscribe(
            (packageProductResponse) => {
                const response = packageProductResponse as PackageProductResponse;
                this.packageObject = response.packageObject as Package;
                this.products = response.products as Material[];

                this.initializePackage();
                this._shoppingcartService.addToCartPackage(this.products, this.packageObject);
            }
        );
    }

    initializePackage() {
        this.products.forEach((value) => {
            value.oldPrice = value.originalPrice;
            value.originalPrice = value.packagePrice;
            value.packageName = this.packageObject.packageName;
        });
        this.products[0].firstElementOfPackage = true;
    }
}
