import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Petofi } from './petofi';
import { HttpClient } from '@angular/common/http';
import { HgWellss } from './hgwells';
import { BannerThumbs } from '../slideshow/banner-thumbs';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
    blogPetofi: Petofi[] = [];

    blogThumb: BannerThumbs[] = [];

    blogThumbUrl = '../../assets/configurations/blog-thumb.json';

    hgWellsUrl = '../../assets/configurations/hgwells.json';

    petofiUrl = '../../assets/configurations/blog_petofi.json';

    hgWells: HgWellss[] = [];

    id = '';

    isPetofi = false;

    isWells = false;
    isCarr = false;
    isPacsirta = false;
    isKazohinia = false;
    isSzinek = false;
    isOmega = false;

    petofiImg = '';
    pacsirtaImg = '';
    carrImg = '';
    wellsImg = '';
    kazohiniaImg = '';
    szinekImg = '';
    omegaImg = '';

    frontendUrl = window.location.origin;

    downloadFileUrl = '';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        private _metaService: Meta,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string
    ) {
        this.downloadFileUrl = _downloadFileUrl;
        this.petofiImg = this.downloadFileUrl + '/petofiblog';
        this.pacsirtaImg = this.downloadFileUrl + '/pacsirtablog';
        this.carrImg = this.downloadFileUrl + '/carrblog';
        this.wellsImg = this.downloadFileUrl + '/retrofuturistablog';
        this.kazohiniaImg = this.downloadFileUrl + '/kazohiniablog';
        this.szinekImg = this.downloadFileUrl + '/szinekblog';
        this.omegaImg = this.downloadFileUrl + '/dromegablog';
    }

    ngOnInit(): void {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        this.id = lastSegmentOfUrl as string;
        this._metaService.updateTag({ property: 'og:url', content: this.frontendUrl + this._router.url });
        if (this.id.includes('petofi')) {
            this._http.get<Petofi[]>(this.petofiUrl).subscribe({
                next: (petofi) => {
                    petofi.forEach((pet) => {
                        pet.url = this.downloadFileUrl + pet.url;
                    });
                    this.blogPetofi = petofi;
                    const imageUrl = this.petofiImg.slice(1);
                    this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
                }
            });
            this.isPetofi = !this.isPetofi;
        } else if (this.id.includes('wells')) {
            this._http.get<HgWellss[]>(this.hgWellsUrl).subscribe({
                next: (hgWells) => {
                    hgWells.forEach((hgwellsElement) => {
                        hgwellsElement.element.forEach((el) => {
                            el.url = this.downloadFileUrl + el.url;
                        });
                    });
                    this.hgWells = hgWells;
                    const imageUrl = this.wellsImg.slice(1);
                    this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
                }
            });
            this.isWells = !this.isWells;
        } else if (this.id.includes('carr')) {
            this.isCarr = !this.isCarr;
            const imageUrl = this.carrImg.slice(1);
            this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
        } else if (this.id.includes('pacsirta')) {
            this.isPacsirta = !this.isPacsirta;
            const imageUrl = this.pacsirtaImg.slice(1);
            this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
        } else if (this.id.includes('kazohinia')) {
            this.isKazohinia = !this.isKazohinia;
            const imageUrl = this.kazohiniaImg.slice(1);
            this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
        } else if (this.id.includes('szinek')) {
            this.isSzinek = !this.isSzinek;
            const imageUrl = this.szinekImg.slice(1);
            this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
        } else if (this.id.includes('omega')) {
            this.isOmega = !this.isOmega;
            const imageUrl = this.omegaImg.slice(1);
            this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
        }

        this._http.get<BannerThumbs[]>(this.blogThumbUrl).subscribe({
            next: (blogThumb) => {
                blogThumb.forEach((blog) => {
                    blog.imageUrl = this.downloadFileUrl + blog.imageUrl;
                });
                this.blogThumb = blogThumb;
            }
        });
    }
}
