import { ApiRequestService, LogService, UseCase, ValidationError } from "@prekog/pw-common";
import { GetPressArticleResponse } from "../getpresarticleresponse";
import { GetPressArticleRequest } from "../getpressarticlerequest";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class GetPressArticleUseCase extends UseCase<GetPressArticleRequest,Observable<GetPressArticleResponse>> {

    getPressArticleForAProductUrl = '/store/get-pressarticlesforaproduct';
    storeBackendUrl = '';

    constructor(_logService: LogService, protected override _http: HttpClient,
        private _apiRequestService: ApiRequestService,
         @Inject('STORE_BACKEND_URL') _storeBackendUrl: string) {
        super(_logService, _http);
        this.storeBackendUrl = _storeBackendUrl;
    }

    protected override executeBusinessLogic(request: void | GetPressArticleRequest): void | Observable<GetPressArticleResponse> {
        return this._apiRequestService
        .sendRequest(this.storeBackendUrl, true, request, true, this.getPressArticleForAProductUrl)
    }

}
