export const environment = {
    production: true,
    isLangugaeSelectable: true,
    backendOrderUrl: 'https://order.multiverzumkiado.hu',
    backendStoreUrl: 'https://store.multiverzumkiado.hu',
    backendEmailUrl: 'https://email.multiverzumkiado.hu',
    backendAuthUrl: 'https://auth.multiverzumkiado.hu',
    backendConfiguratorUrl : 'https://configurator.multiverzumkiado.hu',
    apiUrl: 'https://multiverzumkiado.hu/',
    googleTagContainerId:'GTM-TCWDVXF7',
    googleTagId: 'G-4PB631TGJZ',
    reCaptchaSiteKey:'',
    reCaptchaSecretKey:'',
    downloadUrl:'https://file.multiverzumkiado.hu/file/downloadFile'


};
