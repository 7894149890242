import { ApiRequestService, LogService, UseCase, Void } from "@prekog/pw-common";
import { Banner } from "../banner";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Inject, Injectable } from "@angular/core";
import { GetBannersResponse } from "./getbannersresponse";

@Injectable({
    providedIn: 'root'
})

export class GetSlideshowUseCase extends UseCase<Void, Observable<GetBannersResponse>> {

    //bannerUrl = './assets/configurations/banners.json';

    bannerUrl = '/websiteconfigurator/get-banners';

    configuratorBaseUrl = '';

    constructor(private _apiRequestService:ApiRequestService,@Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,_logService: LogService, protected override _http: HttpClient) {
        super(_logService, _http);
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    protected override executeBusinessLogic(request: void | Void): void | Observable<GetBannersResponse> {
          //return this._http.get<Banner[]>(this.bannerUrl);
          this._apiRequestService.sendRequest<{},GetBannersResponse>(this.configuratorBaseUrl, true, {}, true, this.bannerUrl);
    }

}
