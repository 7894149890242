import { Component,Inject,OnInit, ViewEncapsulation } from '@angular/core';
import { BannerThumbs } from '../slideshow/banner-thumbs';
import { Material } from '../publication/material';
import { PublicationService } from '../publication/publication.service';
import { ApiRequestService, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { HomeConfig } from './home-config';
import { HomeConfigResponse } from './homeconfigresponse';


@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'pw-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    bannerThumbUrl = '../../assets/configurations/blog-thumb.json';

    getHomeConfigsUrl = '/websiteconfigurator/get-homeconfigs';

    homeConfigList:HomeConfig[] = [];

    configuratorBaseUrl = '';

    constructor(
        private _publicationService: PublicationService,
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _translateService: TranslationService
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        this._apiRequestService
        .sendRequest<{}, HomeConfigResponse>(this.configuratorBaseUrl, true, {}, true, this.getHomeConfigsUrl)
        .subscribe((response) => {
            this.homeConfigList = response.homeConfigList as HomeConfig[];
        });

    }

    addToCart(material: Material) {
        this._publicationService.addToCart(material);
    }
}
