import { Component, Inject } from '@angular/core';
import { PublicationService } from '../publication/publication.service';
import { Router } from '@angular/router';
import { ApiRequestService, GoogleTagManagerService } from '@prekog/pw-common';
import { Material } from '../publication/material';
import { GetSoonAvailableProductsResponse } from './getsoonavailablesresponse';
import { FindHomeConfigRequest } from '../published/findhomeconfigrequest';
import { FindHomeConfigResponse } from '../published/findhomeconfigresponse';
import { HomeConfig } from '../home/home-config';

@Component({
    selector: 'pw-soon-published',
    templateUrl: './soon-published.component.html',
    styleUrls: ['./soon-published.component.scss']
})
export class SoonPublishedComponent {
    getSoonAvailableProductsUrl = '/store/get-soon-available-products';

    soonAvailableProducts: Material[] = [];

    storeBaseUrl = '';

    configuratorBaseUrl = '';

    homeConfig: HomeConfig = {};

    getHomeConfigByComponentUrl = '/websiteconfigurator/find-homeconfig';

    constructor(
        private _publicationService: PublicationService,
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _googleTagManagerService: GoogleTagManagerService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        this._apiRequestService
            .sendRequest<{}, GetSoonAvailableProductsResponse>(this.storeBaseUrl, true, {}, true, this.getSoonAvailableProductsUrl)
            .subscribe((response) => {
                this.soonAvailableProducts = response.productList as Material[];
                this.soonAvailableProducts.forEach((soonAvailableMaterial) => {
                    if (soonAvailableMaterial.webshopPriceActive) {
                        soonAvailableMaterial.oldPrice = soonAvailableMaterial.originalPrice;
                        soonAvailableMaterial.originalPrice = soonAvailableMaterial.webshopPrice;
                        soonAvailableMaterial.typeOfPrice = 'Online ár';
                    } else if (soonAvailableMaterial.discountPriceActive) {
                        soonAvailableMaterial.oldPrice = soonAvailableMaterial.originalPrice;
                        soonAvailableMaterial.originalPrice = soonAvailableMaterial.discountPrice;
                        soonAvailableMaterial.typeOfPrice = 'Akciós ár';
                    } else {
                        soonAvailableMaterial.typeOfPrice = 'Ár';
                    }
                });
                this.getHomeConfigName();
                this._googleTagManagerService.viewProductItemList(this.soonAvailableProducts);
            });
    }
    getHomeConfigName() {
        const findHomeConfigRequest: FindHomeConfigRequest = {
            component: 'pw-soon-published'
        };
        this._apiRequestService
            .sendRequest<FindHomeConfigRequest, FindHomeConfigResponse>(
                this.configuratorBaseUrl,
                true,
                findHomeConfigRequest,
                true,
                this.getHomeConfigByComponentUrl
            )
            .subscribe((response) => {
                this.homeConfig = response.homeConfig as HomeConfig;
            });
    }
    addToCart(material: Material) {
        if (!sessionStorage.getItem('token') && material.hasPreorder) {
            sessionStorage.setItem('material', JSON.stringify(material));
            this._publicationService.openLogin(material);
        } else {
            this._publicationService.addToCart(material);
        }
    }
}
