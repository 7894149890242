import { Injectable } from '@angular/core';
import { Material, MaterialElement } from './material';
import { ApiRequestService, ShoppingcartService } from '@prekog/pw-common';
import { ProductsResponse } from './productsresponse';

@Injectable({
    providedIn: 'root'
})
export class PublicationService {

    constructor(private _shoppingcartService: ShoppingcartService) {}

    addToCart(material: Material) {
        this._shoppingcartService.addToCart(material);
    }

    openLogin(material:Material) {
        this._shoppingcartService.openLogin(material);
    }
}
