<div class="slideshow-main-container">
    <div *ngIf="!alive" class="slideshow-dynamic">
        <div routerLink="{{ banner.routerLink }}" class="slideshow-container">
            <img *ngIf="!loading" [@fade] [src]="banner.imageUrl" />
        </div>

        <div class="previous-next-container">
            <div class="buttons">
                <img *ngIf="loading" class="background div0" [src]="logourl" />
            </div>
            <div class="mat-container">
                <button
                    (click)="clickToChangeImage(element)"
                    *ngFor="let element of numberOfClickableSlideshow"
                    [ngStyle]="
                        index === element
                            ? { background: 'black', borderRadius: '50%', border: '2px solid black' }
                            : { background: 'none', borderRadius: '50%', border: '2px solid black' }
                    "
                ></button>
            </div>
        </div>
    </div>

    <div *ngIf="alive" class="slideshow-click">
        <div routerLink="{{ banner.routerLink }}" class="slideshow-container">
            <img *ngIf="!loading" [@fade] [src]="banner.imageUrl" />
        </div>

        <div class="previous-next-container">
            <div class="buttons">
                <img *ngIf="loading" class="background div0" [src]="logourl" />
            </div>
            <div class="mat-container">
                <button
                    (click)="clickToChangeImage(element)"
                    *ngFor="let element of numberOfClickableSlideshow"
                    [ngStyle]="
                        index === element ? { background: 'black', borderRadius: '50%' } : { background: 'none', borderRadius: '50%' }
                    "
                ></button>
            </div>
        </div>
    </div>
</div>
