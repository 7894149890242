<pw-loading-spinner *ngIf="loading"></pw-loading-spinner>
<div class="app">
    <lib-toastmessage></lib-toastmessage>
    <pw-headermodule
        [nameOfTheSite]="nameOfTheSite"
        [logoUrl]="logoUrl"
        [facebookImgUrl]="facebookImgUrl"
        [facebookURL]="facebookUrl"
        [hasSearchBar]="false"
        [hasLogo]="true"
        [hasShoppingCart]="true"
        [hasLogin]="true"
        [hasFacebookIcon]="true"
        [colorOfMobileSidenav]="colorOfMobileSidenav"
    ></pw-headermodule>
    <div class="app-slideshow">
        <app-slideshow *ngIf="router.url === '/'"></app-slideshow>
    </div>
    <pw-breadcrumbmodule></pw-breadcrumbmodule>
    <div class="router-outlet">
        <router-outlet></router-outlet>
    </div>
    <pw-footermodule [facebookURL]="facebookUrl" [footer]="footer"></pw-footermodule>
</div>
<ng-template #footer>
    <div class="elements">
        <div class="multiverzum">
            <a routerLink="/">{{ 'footer.multiverzum' | translate }}</a
            ><br />
        </div>
        <div class="contact-container">
            <div class="contact">
                <div class="span-container">
                    <span>{{ 'footer.emailAddress' | translate }}</span>
                </div>
                <div class="span-container">
                    <span>{{ 'footer.address' | translate }}</span>
                </div>
                <div class="span-container">
                    <span>{{ 'footer.phoneNumber' | translate }}</span>
                </div>
                <div class="span-container-opening">
                    <span>{{ 'footer.openingHours' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="simple-logo">
            <!--Todo: English version needed for SIMPLE instructions-->
            <a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank">
                <img src="{{ simplePayLogo }}" title=" SimplePay - Online bankkártyás fizetés" alt=" SimplePay vásárlói tájékoztató" />
            </a>
        </div>
        <br />
        <div class="documents">
            <div class="documents-inside-box">
                <div class="aszf">
                    <a class="aszf" href="{{ aszfUrl }}" target="_blank"
                        ><span>{{ 'footer.aszf' | translate }}</span></a
                    >
                </div>
                <div class="privacy-policy">
                    <a class="policy" href="{{ dataManagamentUrl }}" target="_blank"
                        ><span>{{ 'footer.policy' | translate }}</span></a
                    >
                </div>
            </div>
        </div>
    </div>
</ng-template>
