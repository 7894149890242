<div class="blog-containers">
    <div class="thumbImgsWells"><img *ngIf="id === 'wells'" [src]="wellsImg" /></div>
    <div *ngIf="isWells" class="element title">
        <h2>H. G. Wells: A felszabadult világ – Egy retrofuturista klasszikus</h2>
        <p>
            Az ember mindig is szerette fürkészni a jövőt; mágia, művészet és tudomány koronként eltérő mértékben szolgáltatták az
            eszközöket ehhez. A XIX. század végén és a XX. század elején a születőfélben lévő tudományos-fantasztikus irodalom volt az a
            médium, amelyen keresztül a kíváncsi tekintetek a jövőbe pillanthattak. És a publikum mindent hálásan fogadott, legyen az Jules
            Verne kissé szikárabb, tudományos megközelítése, az újságok karikaturistáinak vidám, bohókás stílusa vagy a korabeli
            ponyvaregények és képregények harsányabb ábrázolása. Napjainkban ezek a jövőábrázolások ismét megtalálták az utat a közönséghez:
            ma, csodálkozva a korabeli alkotók fantáziáján, elképedve jóslataik és ábrázolásaik prófétikus bölcsességén vagy megmosolyogva
            naív elképzeléseiket szemléljük ezeket az alkotásokat. A mű befogadójának ez a speciális nézőpontja, mellyel a jövőnek azt a
            képét szemléli a múlt alkotásaiban, amely jövő már a befogadó számára is maga is múlt, hozza létre azt a különös, egyedi
            jelenséget, amelyet retrofuturizmusnak nevezünk. A retrofuturizmus ennélfogva sokkal inkább egy érzés, egy értelmezési keret,
            semmint művészeti irányzat, hiszen amikor az alkotások létrejöttek, magukat nem „retrónak”, hanem szimplán csak „futuristának”
            tételezték.
        </p>
        <p>
            A retrofuturista szemléletmód az eltelt idő által jön létre, azon keresztül valósul meg. Határait olykor a műben kijelölt
            időkeretek jelölik ki: amikor a német Hildebrands Csokoládégyár 1900-ban egy reklámkampányában neves művészeket kért fel, hogy
            képeslapokon illusztrálják, szerintük milyen lesz a távoli 2000-es év, nem csupán a retrofuturizmus legelső műveinek egyikét
            alkotta meg, de egyben kijelölte azt az időbeli határt is, ahonnan nézve e bájos képek már „hivatalosan” is retrónak számítanak.
        </p>
        <p>
            (A jövőt ábrázoló képeslapok nagyon divatosak voltak a századfordulón: Bostonban 1908-ban egy Massachusetts városainak jövőjét
            bemutató képeslap-sorozatot adtak ki, amely montázsjellegű szerkesztési stílusával az 1970-es évek amerikai avantgárd művészetét
            is megelőlegezi, a Moses King útikönyvkiadó pedig New Yorkról jelentetett meg a retrofuturista terminológiában a paleofuturista
            jelzővel illetett képeket.) És hasonlóképpen; amikor az 1960-ban induló Atomic Knights című híres képregénysorozat cselekményét
            a távoli 1986-ba helyezte, amikor az 1975–1977 között készült Space: 1999 című legendás sci-fi-sorozat (Magyarországon Alfa
            holdbázis néven futott) az események időpontjának a címben szereplő évet jelölte meg, amikor Ridley Scott 1982-ben született
            klasszikusa, a Szárnyas fejvadász 2019-be helyezte a replikánsok lázadását, mind-mind megalkották e bűvös határvonalat.
        </p>
        <p>
            Persze, nem szükséges konkrét időpont ahhoz, hogy a nézőpont megváltozása régiesnek, meghaladottnak, naivan futurisztikusnak
            tüntessen fel valamit.A technológiai változások néha a legvadabb elképzeléseket is felülmúlják. A telefon, a televízió, a
            repülőgépek, de még az űrhajó is mind ott volt már az alkotói képzeletben, de az internet különleges csodáját (és persze a
            személyi számítógépek mindennapi használatát) még ekkor sem írta le a fantázia (bár el kell ismerni, olykor-olykor nagyon közel
            kerültek hozzá). Ha az 1950-es vagy 60-as évek jövőábrázolásait tekintjük, a retróérzés határait éppen ezek a hiányok jelölik:
            amikor a science fiction magazinok színes borítóképeit nézegetjük, vagy a filmvászonra trükkmesterek által álmodott víziókon
            ámulunk, és az ott ábrázolt jövő képéből hiányzik valami, ami számunkra már megszokott, mindennapi, közönséges, de a korabeli
            jövőábrázolás készítőjének fejében még fel sem bukkant, akkor már a retrofuturizmus terepén kalandozunk.
        </p>
        <p>
            Az idő és a vele változó perspektíva a science fiction klasszikusait sem kíméli; minél bátrabb képzelet rajzolta meg a jövőt,
            minél vadabbul szárnyalt az alkotó fantáziája, annál nagyobb volt az esélye annak, hogy a megjósolt idő leteltekor vagy akár
            annál jóval előbb a mű retrofuturistává érik. Így járt a műfaj egyik megteremtője, Herbert George Wells is.
        </p>
        <p>
            Herbert George Wells nevét a magyar olvasók napjainkban is alighanem jól ismerik, még akkor is, ha mai népszerűsége nem
            vetekszik az 1920-as és 30-as évekbeli ismertségével. Művei sokfelé megtalálhatóak: könyvtárakban, antikváriumokban,
            könyvesboltokban és persze elektronikus formában is. Ma jellemzően tudományos-fantasztikus műveit adják ki sűrűbben: lehengerlő
            stílusuk, izgalmas fordulataik, pergő cselekményük tökéletesen elfedi azt a több mint száz évet, amely megszületésüktől
            napjainkig lepergett. Ha nem is ilyen mértékben, de ismertek realista művei is; az angol kispolgári létet bemutató
            kedves-szomorkás kisregényei, háborús regényei, és talán még a két háború között több kiadást is megért nagy világtörténete is.
        </p>
        <p>
            Ám nem minden műve ilyen szerencsés: szociológiai, eszmetörténeti, politikai tárgyú írásai, legyenek azok klasszikus elbeszélő
            művek, tudományos igényű könyvek vagy irányregények, mára kikoptak a köztudatból. Pedig Wells életművének jelentős részét teszik
            ki ezek a művek, amelyekben a világ sorskérdéseivel foglalkozott egy olyan jövőt elképzelve, amelyben az emberiség létrehozza az
            egész bolygóra kiterjedő államát, politikai, anyagi és eszmei különbségeinek félretételével megvalósítva egy szociálisan
            igazságos társadalmat. Bár Wells minden lehetőséget megragadott, hogy ezen eszméit népszerűsítse és a gyakorlatba is átültesse,
            ezen műveit a világpolitika változásai, úgy tűnik, hamarabb elkoptatták, mint egyéb alkotásait az idő.
        </p>
        <p>
            Minthogy Wells számtalan műfajban mozgott otthonosan, ennek a „politikai feledésnek” áldozatul estek olyan remekművei is,
            amelyekben ötvözte e műfajokat. Mi lesz holnap című regénye, amelyet 1933-ban írt, félig tudományos-fantasztikus regény, félig
            tudományos eszmefuttatás, mégis legjobb műveinek egyike. Az Akik otthon maradtak (ismeretes Mr. Britling címen is) egy
            megrendítő háborús regény, amely egyben politikai kiáltvány is.
        </p>
        <p>
            Talán az ez irányú feledésnek esett áldozatul egy korai fantasztikus műve, a társadalmi-politikai regényei korai előfutárjának
            is tekinthető A felszabadult világ. Wells 1913-ban írta, 1914-ben jelent meg, még az első világháború előtt. Magyarországon már
            csak a háború után, a konszolidáció időszakában adták ki, 1922-ben jelent meg a Népszava Kiadó gondozásában, Havas Lajos
            fordításában, s ezt hamarosan, 1924-ben követte egy második kiadás is. Ezt követően azonban eltűnt a magyar köztudatból, és
            Wells egyéb fantasztikus művétől eltérően ezt nem érte el az 1960-as vagy az 1990-es évek reneszánsza.
        </p>
        <p>A korabeli sajtóban pozitív visszhangja volt a könyv megjelenésének, ezekből gyűjtöttünk össze pár érdekes szemelvényt.</p>
    </div>
    <div class="hgwells-haupt-container">
        <div *ngFor="let hgwells of hgWells" class="hgwells-container">
            <div class="card" *ngFor="let hg of hgwells.element">
                <div class="block icon">
                    <div class="type item">
                        <mat-icon>description</mat-icon>
                    </div>
                </div>
                <div class="block text">
                    <div class="author item">{{ hg.title }}</div>
                </div>
                <div class="block actions">
                    <a class="desktop-ref" href="{{ hg.url }}" target="_blank">Megtekintés</a>
                </div>
                <div class="block action-icons">
                    <a class="mobile-ref" class="description" href="{{ hg.url }}"><mat-icon>description</mat-icon></a>
                </div>
            </div>
        </div>
    </div>
    <div class="thumbImgsPetofi"><img *ngIf="id === 'petofi'" [src]="petofiImg" /></div>

    <div *ngIf="isPetofi" class="museum">
        <h1>Látogatás a Petőfi Irodalmi Múzeumban</h1>
    </div>
    <div class="petofi-container">
        <div *ngFor="let petofi of blogPetofi; let i = index" class="petofi">
            <div class="book-content">
                <div class="image">
                    <a href="{{ petofi.url }}"><img [src]="petofi.url" /></a>
                </div>
                <div class="content">
                    <h3 *ngIf="petofi.title">{{ petofi.title }}</h3>
                    <p>{{ petofi.content }}</p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isCarr" class="carr-container">
        <div class="image">
            <img src="{{ carrImg }}" />
        </div>
        <h2>"A bezártszoba-rejtély"</h2>
        <div class="carr">
            <p class="bold-carr">
                Mi a közös Edgar Allan Poe A Morgue utcai kettős gyilkosság, Agatha Christie Poirot karácsonya, Tíz kicsi néger, Gyilkosság
                ​az Orient expresszen, Sarah Pearse A ​szanatórium, Lucy Foley Vadászparti, Vendéglista és John Dickson Carr A sorozatos
                öngyilkosságok esete című krimijében? Mindegyik történetben gyorsan nyilvánvalóvá válik, hogy „a gyilkos köztünk van”, és a
                gyilkosság vagy gyilkosságok elkövetésének módja nem világos, mert a helyszínen nem is történhetett gyilkosság anélkül, hogy
                a tettes ne lenne maga is a helyszínen…
            </p>
            <p>
                A „bezártszoba-rejtély” egy olyan krimiműfaj, amelyben a bűncselekmény, általában gyilkosság, olyan helyen történik, amit
                látszólag lehetetlen elhagyni. A rejtély kulcsa az, hogyan lehetett az áldozatot meggyilkolni vagy eltüntetni ebben a zárt
                térben anélkül, hogy a tettes nyomot hagyna maga után, vagy a helyszínen ragadna. Ezek a történetek rendkívül izgalmasak és
                elmésen felépítettek, magukba szippantják az olvasót, mert kihívást jelentenek, hiszen az olvasó is „részt vesz” az
                események rekonstruálásában és a nyomok követésében annak érdekében, hogy rájöjjön, mi történhetett valójában. Az olvasás
                így egyben kihívás is, mert menetközben figyelni kell minden apró jelre, és végig kell követni a nyomozó gondolatmenetét,
                felgöngyölítve a megoldandó ügy rejtélyét. Az ilyen történetek legfőbb vonzereje a meglepetésszerűség, a logikai kihívások
                és a váratlan fordulatok.
            </p>
            <p>
                A főszereplő, legyen az detektív, rendőr, amatőr nyomozó, vagy magányos küzdelembe kezd, vagy a többi szereplőt hívja
                segítségül, hogy kinyomozza az események hátterét és felderítse a rejtélyt. A bezárt szoba mindig kulcsfontosságú része a
                történetnek. A bűntények megoldása során a nyomozók általában felfedeznek olyan rejtett ajtókat, titkos járatokat, elrejtett
                kulcsokat vagy más módszereket, amelyek lehetővé teszik a tettes számára, hogy láthatatlan maradjon, vagy éppenséggel
                elmeneküljön a helyszínről. A „bezártszoba-rejtélynek” sokféle változata létezik, mint a klasszikus detektívregények, a
                pszichológiai thrillerek, de még a paranormális elemekkel tarkított történetek is.
            </p>
            <p>
                Ezek a könyvek nemcsak a szórakoztatásra fókuszálnak, hanem az elme megmozgatására, az emberi természet megértésére és a
                logikai gondolkodás aktivizálására is. Az olvasók azzal az izgalommal vetik bele magukat ezekbe a történetekbe, hogy ők
                maguk is részeseivé válhatnak a rejtély megoldásának. Ezek a történetek gyakran kiemelik a pszichológiai és érzelmi
                dimenziókat is. A „bezárt szoba” atmoszférája, a nyomasztó csend és a feszült pillanatok mind hozzájárulnak az érzelmi hatás
                erősítéséhez. Az olvasók azonosulhatnak a főszereplővel, izgulhatnak a veszélyhelyzetben lévő karakterért, és vágyhatnak
                arra, hogy megoldják a rejtélyt.
            </p>
            <p>
                Az ilyen típusú regények között a világszerte ismert John Dickson Carr krimijei szinte iskolapéldának számítanak. Már az
                első krimije, az It Walks By Night (1930) is a bezárt szoba rejtélyére épült.
            </p>
            <p>
                Carr 1906-ban született az Egyesült Államokban, és élete nagy részében folyamatosan utazott az Egyesült Államok, Anglia és
                Franciaország között. Karrierjét újságíróként kezdte, majd később krimiíróként vált ismertté. Számos díjat nyert, beleértve
                az Edgar-díjat is, és élete végéig írt, 1977-ig. Az egyik legnagyobb hatású krimiíró világszerte, jól felépített bűnügyeivel
                és utánozhatatlan humorával azóta is etalonnak számít a krimiírók közt.
            </p>
            <p>
                Carr volt az a szerző, aki a „bezártszoba-rejtély” műfaját igazi művészetté fejlesztette. Munkássága számos írót inspirált,
                temérdek könyvet és filmadaptációt ihletett. A műfaj egyik legismertebb írója volt, és számos regényben alkalmazta a „bezárt
                szoba rejtélyét”.
            </p>
            <p>
                Carr úgy vélte, hogy a „bezárt szoba rejtélyét” a lehető legkreatívabb módon kell variálni, így történeteiben mindig
                találunk valami meglepő és egyedi megoldást. A sorozatos öngyilkosságok esete című regénye remek példa arra, hogyan lehet a
                „bezártszoba-rejtély” elemeit újra felhasználni, miközben a történet továbbra is izgalmas és eredeti marad. A regény során a
                műfaj klasszikus elemeit használja, például a titokzatos helyszíneket, az alaposan megkomponált tervet és a látszólag
                lehetetlen megoldást, amelynek megfejtése azonban egyáltalán nem lehetetlen.
            </p>
            <p>
                Carr nagy hatással volt a detektívregények fejlődésére, a „bezárt szoba rejtély” az egyik legnépszerűbb és legérdekesebb
                stílus a krimi irodalomban. Sok szerző továbbra is alkalmazza, és a műfaj alakulásával a rejtélyek egyre bonyolultabbá és
                izgalmasabbá válnak.
            </p>
            <p>
                A rejtélyek megoldása, a váratlan fordulatok és a karakterekkel való azonosulás mind-mind olyan érzelmeket és gondolatokat
                keltenek, amelyek hosszú ideig elkísérik az embert. Szóval ha szereted a szövevényes sztorikat, akkor mindenképpen érdemes
                „bezárt szoba”-krimiket olvasni, mint az új köntösbe öltözött A sorozatos öngyilkosságok esete című történetet.
            </p>
        </div>
    </div>
    <div *ngIf="isPacsirta" class="pacsirta-container">
        <div *ngIf="id === 'pacsirta'" class="image">
            <img [src]="pacsirtaImg" />
        </div>
        <h2>Kosztolányi Dezső: Pacsirta - egy pszichológus szemével</h2>
        <div class="pacsirta">
            <p>
                Miért olvasnék el egy százéves könyvet? Pláne egy egykori kötelező olvasmányt? Olvasóként és pszichológusként mit kaphatok
                tőle? De talán egy könyvkiadó nem véletlenül adja ki és öltözteti új, modern köntösbe Kosztolányi Dezső Pacsirta című
                regényét…
            </p>
            <p>
                Nem is fontos már, mit tanultam róla a középiskolában, hiszen legtöbbünk nem úgy fogad be irodalmi alkotásokat, mint egy
                „hozzáértő”. Talán elég, ha elmondom, rám hogyan hatott ez a regény, hogyan érintett nőként, édesanyaként, pszichológusként
                ez a történet. Hogy milyen volt olyan szülőkről olvasni, akik így gondolkodnak egyetlen gyermekükről: „azt élesen érezte,
                hogy leánya csúnya. Nemcsak csúnya volt most, hanem hervadt, öreg, igazi vénlány... Mint hernyó a rózsabokor alatt...”
                Szíven ütött a tény, hogy sokkal hamarabb tudtam meg becsmérlő, felszínes külsőségeket a regény főszereplőjéről,
                Pacsirtáról, mint bármi érdemit, ami alapján elképzelhettem volna, ki is ő valójában. Egyáltalán, miért ne lehetne elvárni
                egy apától és anyától, hogy szebbnek, jobbnak lássa a gyermekét, mint amilyen valójában?
            </p>
            <p>
                És ez volt az a pont, amikor úgy éreztem, hogy a mű megfogott. Párbeszédbe elegyedtem Vajkay Ákossal és feleségével, számon
                kértem rajtuk, hogy ugyanazt tükrözik lányuk felé, amit a kegyetlen világ. Aztán ahogy a történet kibontakozott, egyre
                inkább erősödött bennem az érzés, hogy ahogyan az életben, úgy itt sem fekete-fehérek a karakterek. Itt is el kell végeznem
                azt a munkát, hogy a „rossz” és „jó” részeket megpróbálom egységes egészként, integráltan látni.
            </p>
            <p>
                A szeretet számos megnyilvánulásával szembesültem, és csapódtam oda-vissza a szülőkkel való együttérzés és a harag térfelei
                között. Egy sor kérdést is felvetett bennem a mű: Mennyire fontos mások véleménye? Mi akadályoz meg minket abban, hogy olyan
                életet éljünk, amilyet szeretnénk? Mit kezdünk a bennünk rejlő „csúfsággal”? Mikor zárjuk magunkat tudattalanul kalitkába,
                és mi késztethet bennünket arra, hogy tudatunkra ébredve is benne maradjunk? Csupa olyan dilemma, ami ma ugyanolyan
                releváns, mint 1924-ben, a regény megjelenésekor volt.
            </p>
            <p>
                Pszichológus-énemet azok a jelenetek fogták meg leginkább, melyekben tetten érhetőek Freud tanai, például amikor a
                felvállalhatatlan, elfojtott vágyak egy álomban fejeződnek ki, vagy amikor a részegségtől fellazult felettes-én olyan
                gondolatokat is enged a felszínre törni az apában, melyeket még önmagának sem mert kimondani korábban, nemhogy a
                feleségének. A Pacsirta című regénytől nagyon fontos kérdéseket kaptam, melyek, mint tudjuk, néha száz válasznál is előrébb
                visznek önismereti utunkon.
            </p>
        </div>
    </div>
    <div *ngIf="isKazohinia" class="kazohinia-container">
        <div class="image">
            <img src="{{ kazohiniaImg }}" />
        </div>
        <h2>Te olvastad már a Kazohiniát?</h2>
        <div class="kazohinia">
            <span>- Hogy kazomicsodát?</span>
            <span>- Szathmári Sándor? Az meg ki?</span>
            <p>
                Szathmári Sándor Kazohinia című disztópiája az egyik legkalandosabb történetű magyar regény, amely 1941-ben jelent meg, és
                már akkor is cenzorok keze vigyázta. Gulliver világhírű történeteinek sorába illeszkedik, és Huxley Szép új világ és Orwell
                1984 című könyvei mellett van helye a könyvespolcon. Karinthy Frigyes és Babits Mihály is méltatta a hol sírásra, hol
                nevetésre fakasztó szatírát, amelynek angol nyelvű kiadása százezres példányszámban jelent meg. A szatíra Gulliver ​utazása
                Kazohiniában és Utazás ​Kazohiniában címmel is megjelent.
            </p>
            <p>
                Gulliver elhivatottan és hűen szolgálja hazáját, Nagy-Britanniát a hadiflottánál, mígnem egy nap hajótörést szenved, és egy
                szigeten tér magához. Ez az ismeretlen új világ Kazohinia. Érkezését követően a hinek közé kerül, akik látszólag tökéletes
                társadalmi rendszerben élnek. Gulliver megtanulja a nyelvüket, és napról napra azzal szembesül, hogy a hineknek nincsenek
                érzelmei, barátai, sőt családja sem. Gulliver képtelen elviselni a magányt, ezért azt kéri a hinektől, hogy vezessék a
                titokzatos behinek közé, hátha köztük emberi kapcsolatokat tud létesíteni. Ám belépve a világon belüli világba, minden
                megkérdőjeleződik, amit Gulliver valaha tudni vélt…
            </p>
            <p>
                Szathmári Sándor fő művének sajnos nem sikerült a neki járó népszerűséget és elismertséget megszereznie sem saját korában,
                sem azóta. Pedig valami igazán nagyszabásút alkotott, melyre világszerte kíváncsiak voltak az olvasók. „A Kazohinia sosem
                robbant be tehát a mainstreambe, és mégis bő nyolcvan éve mindig van, volt, és valószínűleg lesz is rajongótábora" – írja
                Bódi Péter, a regény és Szatmári Sándor könyveinek egyik újrafelfedezőije.
            </p>
            <p>
                Nézzük, mások szerint miért érdemes elolvasni ezt a remekművet, hogyan látják a Kazohinia világát a 2000-es években írók és
                kritikusok.
            </p>
            <p>
                „Tizennégy éves koromban két vége volt a világnak: Kazohinia és Óceánia. Minden meg volt bennük oldva, szerettem az ilyen
                világokat. Szatmárinál csak a valóság létezik, és ebből következik minden földi jó: ingyenszex, -lakás és -kaja. Orwellnél
                meg csak a valóság nem létezik, és ebből származik a helyi értéken legmagasabb polcra való földi jó: lebegés,
                halhatatlanság, világuralom, minden megoldható, ha a kollektív én, a Párt, úgy akarja. Két-három olvasás után Orwell
                döccenői bizonyultak idegesítőbbnek – mindenekelőtt azért, mert a Kazohinia humoros, mint a jó irodalom gyakran, mert
                voltaképp az is; Orwell meg unalmas, mint egy rocksztár, mert voltaképp az is.
            </p>
            <p>
                Nem tudom, mennyiben változott a helyzet az én fiatalkorom óta (ennek kiszámításához: 1984-ben születtem), de életem
                tiszteletreméltó figurái közül kimagaslóan sokan olvasták és szerették a Kazohiniát. A gimnáziumi gólyatáborom főszervezője,
                a 2005 nyarán megismert barátnőm apja, az esküvői tanúm, illetve a barátaim körében legaktívabb író mind befogadóan
                mosolyodtak el, amikor kiderült, hogy én is. Mintha valami titkos szekta lennénk, a kona kékfülű frakciója, akik szerint
                nincs is nagyobb böto, mint a lamik kemonok anebáin nevetni. Jó lenne, ha elolvasnánk annyian a Kazohiniát, hogy ne csak az
                efféle összekacsintást tegye lehetővé, ami a hasonszőrűekre jellemző, hanem igazi, közös tudás legyen. Mert annak sosem
                voltunk bővében, és egyre kevesebb van belőle.”
            </p>
            <p>Szolcsányi Ákos, költő, műfordító, 2023</p>
            <p>
                „Karinthy, aki a még javítatlan, ugyanakkor még cenzúrázatlan változatot olvasta kéziratban, úgy nyilatkozott, hogy minden e
                tárgyban írott munkáját odaadná a Kazohiniáért; Babits, aki a stilisztikailag megigazított, ám a cenzúra által megcsonkított
                első kiadást olvasta a halála előtt, komolyan fontolóra vette a művet a Baumgarten-díj esélyesei között. A Nyugatban is
                komoly recenzió jelent meg a regényről, aligha függetlenül Babitstól.”
            </p>
            <p>Mester Béla: „Nincs út jobb hazába, hiába, hiába, hiába!” [Intés az őrzőkhöz] Liget Műhely, 2019.04.01.</p>
            <a href>https://ligetmuhely.com/liget/nincs-ut-jobb-hazaba-hiaba-hiaba/</a>
            <p>
                „A Kazohinia egy olyan filozofikus regény, amely magából a filozófiából is viccet csinál – sőt talán pont ez a szatirikusabb
                rész legszellemesebb eleme. Szathmári nem egy adott eszmerendszer mellett foglal állást, és nem is kortárs politikai
                mozgalmaknak állít ellenpéldát – habár ezek erős hatással vannak a víziójára. Sok olyan regény van, amely úgy próbálja meg
                mély értelmű műként eladni magát, hogy alapigazságokat fogalmaz meg bonyolult, cikornyás körmondatokban, hogy aztán az
                olvasó hosszas gondolkodás után rájöhessen valamire, amit már amúgy is tudott, míg Szathmári az alapvető társadalmi
                szabályainkat vizsgálja, és olyan kérdésekről gondolkodtat el, amelyek mellett megszokásból minden nap elmegyünk.”
            </p>
            <p>
                „…a Kazohiniát egy introvertált, magát folyamatosan képző, művelt, több területen otthonosan mozgó mérnök írta, aki makacsul
                szembement az elvárásokkal, és amellett, hogy ez az egyik legnagyobb érdeme, sajnos ezzel is ásta el magát az
                irodalomkritika előtt. Ha mégis van a Kazohiniának általános üzenete, az talán valami ilyesmi: egészen addig, amíg a
                társadalmunk alapja a versengés, nem azt próbáljuk meg elérni, hogy mi legyünk a „legjobbak” (leggazdagabbak, legjobb
                teljesítménnyel bírók stb.), hanem azt, hogy az általunk elképzelt sorban a többiek mögöttünk legyenek.”
            </p>
            <p>Bódi Péter: A legismertebb ismeretlen regény Kortárs Online, 2021.07.12.</p>
            <a href>https://kortarsonline.hu/aktual/kazohinia.html</a>
            <p>
                „A Kazohinia remekmű volta ellenére (aligha akad irodalompártoló ember, aki, ha olvasta, más véleménnyel lehetne róla)
                jóformán ismeretlen mű, holott a középiskolákban kötelező olvasmányként szerepelhetne. A jó irodalomkritika a legtöbb
                esetben nem felelős gyönge művek népszerűsítéséért. De a Kazohinia esetében erős lelkiismeret-furdalást érezhetnek a
                kritikusok.”
            </p>
            <p>Grendel Lajos: Néhány gondolat… Szathmári Sándor Kazohinia című regényéről (esszé) Irodalmi Szemle, 2014.04.05.</p>
            <a href>https://irodalmiszemle.sk/2014/04/grendel-lajos-nehany-gondolat-szathmari-sandor-kazohinia-cim-regenyerl-essze/</a>
            <p>
                „Szathmári ezzel a két világgal az emberi lét két lehetséges végletét mutatja be. Az egyikben mindent az ész ural és
                irányít. A hinek nem boldogok, de nem is boldogtalanok, hiszen nem merül fel bennük ez a kérdés, egyszerűen léteznek. A
                behinek sem boldogok, de mindent megtesznek saját sanyargatásuk érdekében. Semmilyen praktikus dolog nem foglalkoztatja
                őket, csak a léleknek élnek. Egyik sem egészséges életforma. …Bár az író nem tört irodalmi babérokra, nem szépirodalmi művet
                akart írni, mégis maradandót alkotott. A stílusa magával ragadó, szórakoztató, a történet fantáziadús.”
            </p>
            <p>Könyvajánló: Szathmári Sándor: Kazohinia Sulinet, 2001.02.21.</p>
            <div class="link">
                <a href="https://hirmagazin.sulinet.hu/hu/muveszetek/konyvajanlo-szathmari-sandor-kazohinia">Kazohinia könyvajánló</a>
            </div>
        </div>
    </div>

    <div *ngIf="isSzinek" class="szinek-container">
        <div class="image">
            <img src="{{ szinekImg }}" />
        </div>
        <h2>Kaffka Margit: Színek és évek</h2>
        <div class="szinek">
            <p>
                Ismét egy olyan könyvajánlót készítettem Nektek, ami teljesen szubjektív, kérlek, ne tekintsétek hiteles műelemzésnek!
                Benyomásokról, érzésekről írok, amelyek megjelentek bennem Kaffka Margit Színek és évek című regényét olvasva, melyet már
                megjelenésének idején (1911-ben) a női sors és lélek hiteles ábrázolásának tekintettek. A következő felütéssel kezdődik az
                elbeszélés: „Szép, nagy csendesség van körülöttem jó ideje már. Messzire tőlem csak megy tovább az élet, baj, osztozás,
                iparkodások, és ha néha kicsit felé nézek, elcsudálkozom, hogy az életet most élő emberek milyen gyerekesen kíváncsiak rá,
                hogy mi fog velük történni holnap vagy holnapután. És furcsa elgondolni, hogy a mostani dolgok a fiatalok számára éppolyan
                újak és érdekesek, mint nekem a harminc évvel ezelőttiek. Az én mostani látásommal nézve már világos, hogy a sokféle emberi
                hajszában és változásban nagyon sok a játékos szándék. Ahogy a gyerek azt mondja: boltocskát játszom vagy papát vagy tengeri
                vihart - úgy játssza belé magát a felnőtt ember is a célratörő, a szorgos, a léha, a szenvedélyes vagy a gyűlölködő
                szerepébe. Valamivel ki kell töltenie az időt; el kell hitetni magunkkal egy s más dologról egy időre, hogy az fontos. Mert
                különben egybekulcsolt kezekkel ülnénk az útszélen, és talán ez volna a természetes - minden egyéb csak magahitető
                fontoskodás.” 37 éves koromra, néhány egzisztenciális krízissel a hátam mögött elmondhatom, hogy pontosan értem, miről
                szólnak a százéves sorok, csak én sosem tudtam ilyen szépen megfogalmazni. Tudjátok, amikor már a reggeli kávé előtt
                megkérdőjelezi az ember az egész életét, hogy tényleg úgy él-e, ahogyan kellene; hogy fontos-e, amit csinál, vagy csak
                szeretné hinni, hogy az; hogy mi értelme bárminek, amikor néhány év és senki nem fog emlékezni sem ránk, sem az
                aggodalmainkra, ténykedéseinkre.
            </p>
            <p>
                Olvasás közben olyan érzésem támadt, mintha valamelyik női felmenőm gondolataiba leshetnék bele, életbölcsességek után
                kutatva, vagy olyan forrás vizéből kortyolhatnék, amit egy titkos dzsungel mélyén találtam. Mert ahogy a sűrű vadonban
                barangolni is erőfeszítést igényel, úgy a régi klasszikusok olvasása is megdolgoz bennünket. Munka ez, én mégis amellett
                érvelek, hogy megéri néha ilyen műveket is megismerni, hiszen közelebb kerülhetünk univerzális, időn és téren áthatoló
                igazságokhoz. Az új ismeretek megszerzésén túl az olvasásnak számos jótékony hatása van az idegrendszerünkre nézve is,
                melyek közül az egyik legfontosabb, hogy a sorokba merülve egy irányba fókuszálhatjuk a figyelmünket. Ez a mai modern
                világban, ahol egyszerre többszáz inger zúdul ránk, olyan érzés az agyunknak, mint egy zen elvonulás. Olvassátok a következő
                sorokat ebben a szellemiségben, csendesedjetek el, és csak engedjétek, hogy hasson rátok: „De így egészben néha oly
                egyszövésűnek tetszik ez a nagy halom esztendő; szinte elcsudálkozom; hát annyi tömérdek nap minden reggelén külön
                felkészültem az életre, öltöztem, beszéltem, gondoskodtam és küszködtem, és annyi éven át mindannyiszor mondtam néha nyüzsgő
                bajokból, gondokból eszmélve egy-egy pillanatra: "Bizony, hull a levél, hidegedik! Nemsokára fűtünk!" Vagy sóhajtottam nehéz
                közönnyel az égre: "Haj, esős nyár van! Tavaly szebben nyílt a leander!" Vagy ezt: "Itt van megint a karácsony; ez is, az is
                kellene!"... Összemosódó, nehéz és szívós, elsötétült színű szövete az időnek, egy hosszú, hanyatló, tompán nehezülő
                életszakasz; de mennyi alattomos, keserves küzdésekkel, mennyi buta, említeni is bajos, regényietlen, senyvesztő és
                lekoptató nyomorúságok csatározásaival átlyuggatott, szétrongyolt! Megőröltek, elnyűttek engem az évek! De vajon finom és
                csinos, halk és szépen nyugodalmas életben nem öregedtem volna-e meg szintúgy? Ott volnék, ahol vagyok! Most már nem is
                tűnődöm rajta, hogy hol hibáztam el. Tán mindenkinek az élete a természete szerint fejlődik; vagy a lénye alakul hozzá a
                körülményeihez. Én már el nem tudom képzelni önmagamat másféle múlttal és jelennel, mint ami így a részemmé vált, ilyenné
                formált.”
            </p>
            <span [ngStyle]="{ 'margin-bottom': '8px' }">Cím: Olvassunk egy pszichológus szemével: Kaffka Margit: Színek és évek</span>
            <span>Írta: Rácz-Vermes Petra</span>
        </div>
    </div>
    <div *ngIf="isOmega" class="omega-container">
        <div class="image">
            <img src="{{ omegaImg }}" />
        </div>
        <h2>Ki vagy, (Omega) doki?</h2>
        <div class="omega">
            <span>
                „Arnould Galopin a francia Conan Doyle. Különbség kettőjük között az, hogy Galopin a népszerűségért nem áldozza fel
                művészetét”– írta a Pesti Hírlap 1926-ban, arra utalva, hogy a méltatott szerző nem csupán a ponyva-, de a szépirodalom
                területén is jeleskedett. S csakugyan: Galopin a Francia Akadémia nagydíját is elnyerte a Sur le Front de Mer című
                regényével, amely az I. világháború haditengerészeinek életét mutatja be. </span
            ><br />

            <span>
                Ennek ellenére nevét kevés irodalmi lexikonban találjuk meg, s még a fantasztikus irodalom kedvelői közül is kevesebben
                ismerik, mint kortársaiét. Az 1863-ban született Arnould Galopin viszonylag későn, tízévnyi újságíróskodás után, negyvenéves
                korában írta meg első regényét. Utána azonban nem volt megállás, valósággal ontotta magából a műveit, 1934-ben bekövetkezett
                haláláig több mint ötven könyvet írt. A Pesti Hírlap Vasárnapja egyik szerzője, Szentgyörgyi Ferenc, maga is számos ponyva
                írója, a lap 1927. május 15-i számában kissé pikírten úgy fogalmaz: „…bámulni kell e termékenység csimborasszóján. És
                Arnould Galopin még alig ötvenesztendős. Nagyszerű színben van és kitűnő egészségnek örvend. Micsoda ponyvairodalmi múlt
                lesz Arnould Galopin mögött, mondjuk: húsz esztendő múlva?! Még rágondolni sem jó.” </span
            ><br />
            <span>
                Ám az írót a magyar olvasóközönség is a kegyeibe fogadta, igaz, jobbára csak mint detektívregények szerzőjét ismerték (és
                ismerik ma is). 1927-ben jelent meg magyarul A vöröstéglás villa rejtélye és A vörös szandál című regénye, ezt követte
                1930-ban
            </span>
            <span>
                A milliomos titka, majd 1933-ban a Hajsza a detektív után. Ezen művekből több újabb kiadásban is megjelent a
                rendszerváltozás után is. Galopin a Doktor Omega című fantasztikus regényét 1906-ban írta, s a magát mindig, minden
                helyzetben feltaláló doktor figurája hamar népszerű lett. Olyannyira, hogy a Tallandier Kiadó 1908-ban
            </span>
            <span
                >folytatásokban is megjelentette egy ifjúsági magazinban Az ismeretlen felfedezői címmel. 1949-ben egy reprint kiadás
                elevenítette fel a fantasztikus kalandokat, ehhez Armand Rapeno francia illusztrátor készített hangulatos rajzokat
                (melyekből jó néhányat kötetünkben is megtalál az olvasó).</span
            ><br />
            <span>
                Bár Galopin nem írt doktor Omega főszereplésével több regényt, a professzor kalandjai nem értek véget az író halála után
                sem. A bátor doktor egy különleges irodalomtörténeti lexikonnak köszönhette újrafelfedezését. Jean-Marc Lofficier író, szer-
                kesztő, forgatókönyvíró és társa, Randy Lofficier 2000-ben French
            </span>
            <span>
                Science Fiction, Fantasy, Horror and Pulp Fiction címen megjelentette a francia science fiction és ponyvairodalom
                enciklopédiáját, s ebben rövid bejegyzést szántak Galopin munkásságának is. Ez a rövid lexikoncikk Kevin O’Neill
                grafikusművészt arra inspirálta, hogy a Doktor Omega történettel kapcsolatosan két hivatkozást is elhelyezzen Alan Moore
                Különleges úriemberek szövetsége című képregényében. Ez a képregény igen nagy népszerűségnek örvendett a rajongók körében
                (olyannyira, hogy 2003-ban film is készült belőle A Szövetség címen, s állítólag készül már az új feldolgozás is), nem
                csupán fordulatos cselekményszövése okán, hanem azért is, mert egy különleges alternatív világot teremtett, amelyben a XIX.
                század végi, XX. század eleji ponyvaregények hősei együtt, egyszerre vannak jelen. Ennek a különleges univerzumnak, amelyben
                Drakula találkozhat Nemo kapitánnyal, ahol Dorian Gray dr. Jekyll-lel parolázhat, vagy ahol dr. Moreau London sikátoraiban
                végezheti kétes élveboncolási kísérleteit, míg Sherlock Holmes vadászik rá, külön irodalmi antológiája is született. A Tales
                of the Shadowmen című, szintén Jean-Marc és Randy Lofficier által szerkesztett periodika immáron több mint tizenöt számot
                tudhat maga mögött, s már az első számában felbukkan Doktor Omega is. </span
            ><br />
            <span>
                Jean-Marc Lofficier azonban nem érte be Arnould Galopin méltatásával, hanem nekilátott a Doktor Omega angol nyelvű
                fordításának is. Végül aztán nem csupán lefordította a művet, hanem újra is írta. Észrevette ugyanis, hogy doktor Omega
                alakja milyen nagy mértékben hasonlít a híres Doctor Who-történetek főszereplőjére. Doctor Who (Ki vagy, doki?) az 1963 óta
                műsoron levő, a BBC által készített brit televíziós sci-fi sorozat főhőse volt, egy különleges képességű földönkívüli
                teremtmény, időutazó, aki azonban megjelenésében egy kopaszodó, öreg
            </span>
            <span>
                tudósra hasonlít. A könyv 2003-ban jelent meg, s ezzel Omega doktor elnyerte a helyét a halhatatlanok csarnokában.
                Kétszeresen is: egyfelől a doktor kalandjaiból (régiből és újból egyaránt) egyre-másra születnek azóta is a képregényes
                feldolgozások, de már hangoskönyv formájában is elérhetőek, s nem úgy tűnik, hogy csökkenne az érdeklődés a figura után.
                Másfelől doktor Omega az alternatív univerzumban is elnyerte a halhatatlanságot, hiszen a Lofficier-féle átiratból kiderül,
                hogy nem más ő, mint a Ki vagy, doki? híres főhősének első reinkarnációja.
            </span>
        </div>
    </div>
</div>
