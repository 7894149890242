import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService } from '@prekog/pw-common';
import { GetPressArticleResponse } from './getpresarticleresponse';
import { GetPressArticleRequest } from './getpressarticlerequest';
import { PressArticle } from './pressarticle';
import { BooksComponent } from '../books/books.component';
import { ProductsResponse } from '../publication/productsresponse';
import { Material } from '../publication/material';
import { GetPressArticleUseCase } from './usecase/get-pressarticle-usecase';
import { Observable } from 'rxjs'

@Component({
    selector: 'pw-pressarticle',
    templateUrl: './pressarticle.component.html',
    styleUrls: ['./pressarticle.component.scss']
})
export class PressarticleComponent implements OnInit {
    getPressArticleForAProductUrl = '/store/get-pressarticlesforaproduct';

    storeBackendUrl = '';

    getPressArticleForAProductResponse?: GetPressArticleResponse = {};

    pressArticleList?: PressArticle[] = [];

    id = 0;

    getProductsUrl = '/store/get-products';

    productResponse: ProductsResponse = {};

    materials: Material[] = [];

    productId = 0;

    downloadFileUrl = '';

    pressArticleUrl = '';

    constructor(private _getPressarticleUseCase:GetPressArticleUseCase,private _apiRequestService: ApiRequestService, @Inject('STORE_BACKEND_URL') _storeBackendUrl: string,@Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string) {
        this.storeBackendUrl = _storeBackendUrl;
        this.downloadFileUrl = _downloadFileUrl;
        this.pressArticleUrl = this.downloadFileUrl+'/pressarticleicon';
    }

    ngOnInit(): void {
        this._apiRequestService.sendRequest(this.storeBackendUrl, true, {}, true, this.getProductsUrl).subscribe((productList) => {
            this.productResponse = productList as ProductsResponse;
            this.materials = this.productResponse.productList as Material[];
            const lastSegmentOfUrl = window.location.href.split('/').pop();
            this.materials.forEach((material) => {
                if (lastSegmentOfUrl!.includes(material.routerLink as string)) {
                    this.productId = material.id!;
                }
            });
            const getPressArticleForAProductRequest: GetPressArticleRequest = {
                productId:this.productId
            };

            // this._apiRequestService
            //     .sendRequest(this.storeBackendUrl, true, getPressArticleForAProductRequest, true, this.getPressArticleForAProductUrl)
            (this._getPressarticleUseCase.execute(getPressArticleForAProductRequest) as Observable<GetPressArticleResponse>)
                .subscribe((answer) => {
                    this.getPressArticleForAProductResponse = answer as GetPressArticleResponse;
                    this.pressArticleList = this.getPressArticleForAProductResponse.pressArticleList;


                });
        });

    }

    //TODO call url: "/store/get-pressarticlesforaproduct" to get pressarticles from store
}
