import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GetProfessionalMaterialContentRequest } from './get-professional-material-content.usecase-request';
import { GetProfessionalMaterialContentResponse } from './get-professional-material-content.usecase-response';
import { HttpClient } from '@angular/common/http';
import { BusinessLogicError, LogService, UseCase, ValidationError } from '@prekog/pw-common';

@Injectable({
    providedIn: 'root'
})
export class GetProfessionalMaterialContentUseCase extends UseCase<
    GetProfessionalMaterialContentRequest,
    Observable<GetProfessionalMaterialContentResponse>
> {
    constructor(_logService: LogService, protected override _http: HttpClient) {
        super(_logService, _http);
    }

    protected override validate(request: GetProfessionalMaterialContentRequest) {
        if (!request.materialFileName) {
            throw new ValidationError('Material filename cannot be empty!');
        }
    }

    protected executeBusinessLogic(request: GetProfessionalMaterialContentRequest): Observable<GetProfessionalMaterialContentResponse> {
        return this._http.get(request.materialFileName, { responseType: 'text' }).pipe(
            map((materialContent: string) => {
                if (!materialContent) {
                    throw new BusinessLogicError("Couldn't read material file content!");
                }
                const response = { content: materialContent };
                return response;
            })
        );
    }
}
