import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService, ShoppingcartService } from '@prekog/pw-common';
import { Material } from '../publication/material';
import { Package } from './package';
import { DiscountedProductResponse } from './discountedproductresponse';
import { SpecialofferService } from './specialoffer.service';
import { PackageProductRequest } from '../discountedproduct/packageproductrequest';
import { PackageProductResponse } from '../discountedproduct/packageproductresponse';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from '@prekog/pw-common';
import { GetPackageProductUseCase } from '../discountedproduct/usecase/get-package-product-usecase';
import { Observable } from 'rxjs/internal/Observable';
import { GetDiscountedProductsUseCase } from './usecase/get-discounted-products-usecase';

@Component({
    selector: 'pw-specialoffer',
    templateUrl: './specialoffer.component.html',
    styleUrls: ['./specialoffer.component.scss']
})
export class SpecialofferComponent  {

}
