<div class="materials-container">
    <div *ngIf="soonAvailableProducts.length > 0" class="soon-published">
        <div class="soon-pub-home">
            <h3>{{ homeConfig.name | uppercase }}</h3>
        </div>
    </div>
    <div *ngIf="soonAvailableProducts.length > 0" class="soon-container-home">
        <div class="main">
            <div *ngFor="let publication of soonAvailableProducts; let i = index" class="material-container-soon">
                <div *ngIf="publication.routerLink !== 'morte_taska'" class="books">
                    <a *ngIf="router.url === '/'" routerLink="/book/{{ publication.routerLink }}"><img [src]="publication.url" /></a>
                    <a *ngIf="router.url !== '/'" routerLink="{{ publication.routerLink }}"><img [src]="publication.url" /></a>
                </div>
                <div *ngIf="publication.routerLink !== 'morte_taska'" class="book-infos">
                    <p>{{ publication.author }}</p>
                    <p>{{ publication.title }}</p>
                    <p class="available">{{ 'materials.expectedPublicationDate' | translate }} {{ publication.available | date }}</p>
                    <span *ngIf="publication.webshopPriceActive || publication.discountPriceActive">
                        Ár a könyvön: {{ publication.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </span>
                    <p [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                        {{ publication.typeOfPrice }} {{ publication.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </p>
                </div>
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
</div>
